import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icon from "../components/icons/icon";
import { mainDb } from "../helpers/db.model";
import { AppointmentTypesInterface } from "../helpers/dbTypes";
import { ResponseType } from "../helpers/types";
import {
	capitalizeFirstCharacter,
	getNameInitials,
	isDateValid,
	isSelectedDateTimeBeforeCurrentDateTime,
	isToDateTimeBeforeOrSameAsFromDateTime,
} from "../helpers/utils";
import { useNewAppointmentApi } from "../hooks/newAppointment";
import { MuiTheme as theme } from "../styles/theme";
import CustomLoader from "./CustomLoader";
import SnackBar from "./SnackBar";
import useGlobalConfig from "../hooks/useGlobalConfig";

dayjs.extend(utc);
interface NewAppointmentModalProps {
	open: boolean;
	onClose: () => void;
	primaryPatientId?: number;
	refreshData?: () => void;
	page?: "lead" | "patient";
}

interface DoctorType {
	id: number;
	name: string;
}

interface PrimaryPatientType {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	partner: {
		first_name: string;
		id: number;
		last_name: string;
	} | null;
}

interface SecondaryPatientType {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
}

interface LocationType {
	id: number;
	name: string;
	address: string;
	latitude: string;
	longitude: string;
	status: number;
	created_at: string;
	updated_at: string;
	pivot: {
		appointment_type_id: number;
		location_id: number;
	};
}

interface AppointmentType {
	id: number;
	name: string;
	visit_type: "virtual" | "onsite" | "both";
	duration: number;
	locations: Array<LocationType> | null;
}

interface FormInputFields {
	primaryPatient: PrimaryPatientType | null;
	secondaryPatient: SecondaryPatientType | null;
	doctor: DoctorType | null;
	invitePrimary: boolean;
	inviteSecondary: boolean;
	dateOfAppointment: Dayjs;
	appointmentFromTime: Dayjs;
	appointmentToTime: Dayjs;
	appointmentType: AppointmentType | null;
	visitType: "virtual" | "onsite" | "";
	clinicLocation: LocationType | null;
}

export default function NewAppointmentModal({
	open,
	onClose,
	primaryPatientId,
	refreshData,
	page = "lead",
}: NewAppointmentModalProps) {
	const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
	const [snackBarMessage, setSnackBarMessage] = useState<string>("");
	const [snackBarType, setSnackBarType] = useState<
		"success" | "error" | "warning" | "info"
	>("success");
	const [errors, setErrors] = useState<{
		data: Array<any>;
		message: string;
		success: boolean;
	} | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [patients, setPatients] = useState<Array<PrimaryPatientType>>([]);
	const [doctors, setDoctors] = useState<Array<DoctorType>>([]);
	const [patientRelationshipStatus, setPatientRelationshipStatus] =
		useState<string>("");
	const [AppointmentTypes, setAppointmentTypes] = useState<
		AppointmentTypesInterface[]
	>([]);
	const [hideLocation, setHideLocation] = useState(false);

	const { createAppointment, getAppointmentModalFieldsData } =
		useNewAppointmentApi();

	const {
		formState: { errors: formErrors },
		handleSubmit,
		control,
		reset,
		getValues,
		setValue,
	} = useForm<FormInputFields>({
		mode: "onTouched",
		defaultValues: {
			primaryPatient: null,
			secondaryPatient: null,
			doctor: null,
			invitePrimary: true,
			inviteSecondary: true,
			dateOfAppointment: dayjs(),
			appointmentFromTime: dayjs(),
			appointmentToTime: dayjs(),
			appointmentType: null,
			visitType: "",
			clinicLocation: null,
		},
	});

	const primaryPatient = useWatch({
		control,
		name: "primaryPatient",
	});

	const secondaryPatient = useWatch({
		control,
		name: "secondaryPatient",
	});

	const appointmentType = useWatch({
		control,
		name: "appointmentType",
	});

	const visitType = useWatch({
		control,
		name: "visitType",
	});

	const onSnackBarClose = () => {
		setOpenSnackBar((prev: boolean) => !prev);
	};

	const setInitialValues = () => {
		setErrors(null);
		setLoading(false);
		setPatients([]);
		setDoctors([]);
		setAppointmentTypes([]);
		reset();
	};
	const { t } = useTranslation();

	const getConfig = async () => {
		const globalConfig = await mainDb.globalConfig.toArray();
		const iAppointmentLocationHide = globalConfig.find(
			(item: any) => item.key === "is-appointment-location-hide",
		);
		setHideLocation(
			iAppointmentLocationHide && iAppointmentLocationHide.value === "1"
				? true
				: false,
		);
	};

	//UseEffect to get patients and doctors
	useEffect(() => {
		const getAppointmentModalData = async () => {
			// Getting appointment data
			const appointmentType = await mainDb.appointmentTypes.toArray();
			const doctors = await mainDb.doctorList.toArray();
			await getAppointmentModalFieldsData({
				...(primaryPatientId ? { id: primaryPatientId } : {}),
				isPatientInLeads: page === "lead" ? true : false,
				setLoading,
				setErrors,
			})
				.then((response: ResponseType) => {
					if (response.success) {
						const patientData = primaryPatientId
							? response.data.patient
							: null;
						const doctorsData = response.data.doctor_list;
						const appointmentTypesData =
							response.data.appointment_types;

						// Formatting patient data
						if (patientData !== null) {
							if (page === "lead") {
								const selectedPrimaryPatientObject: PrimaryPatientType =
									{
										id: patientData.patient.id,
										first_name:
											patientData.patient.first_name,
										last_name:
											patientData.patient.last_name,
										email: patientData.patient.email,
										partner: patientData.patient.partner,
									};
								setValue(
									"primaryPatient",
									selectedPrimaryPatientObject,
								);

								if (patientData.patient.partner) {
									const selectedSecondaryPatientObject: SecondaryPatientType =
										{
											id: patientData.patient.partner.id,
											first_name:
												patientData.patient.partner
													.first_name,
											last_name:
												patientData.patient.partner
													.last_name,
											email: patientData.patient.partner
												.email,
										};

									setValue(
										"secondaryPatient",
										selectedSecondaryPatientObject,
									);
								} else {
									setValue("inviteSecondary", false);
								}

								const patientRei: DoctorType | null = response
									.data.patient.rei
									? {
											id: patientData.patient.rei.id,
											name: patientData.patient.rei.name,
									  }
									: null;

								if (patientRei) setValue("doctor", patientRei);
								setPatientRelationshipStatus(
									patientData.patient.relationship_status,
								);
							} else if (page === "patient") {
								const selectedPrimaryPatientObject: PrimaryPatientType =
									{
										id: patientData.id,
										first_name: patientData.first_name,
										last_name: patientData.last_name,
										email: patientData.email,
										partner: patientData.partner,
									};
								setValue(
									"primaryPatient",
									selectedPrimaryPatientObject,
								);

								if (patientData.partner) {
									const selectedSecondaryPatientObject: SecondaryPatientType =
										{
											id: patientData.partner.id,
											first_name:
												patientData.partner.first_name,
											last_name:
												patientData.partner.last_name,
											email: patientData.partner.email,
										};

									setValue(
										"secondaryPatient",
										selectedSecondaryPatientObject,
									);
								} else {
									setValue("inviteSecondary", false);
								}

								const patientRei: DoctorType | null = response
									.data.patient.rei
									? {
											id: patientData.rei.id,
											name: patientData.rei.name,
									  }
									: null;

								if (patientRei) setValue("doctor", patientRei);
								setPatientRelationshipStatus(
									patientData.relationship_status,
								);
							}
						}

						// Formatting doctors data
						const formattedDoctorsData = doctors.map(
							(item: {
								created_at: string;
								email: string;
								id: number;
								name: string;
								status: boolean;
								type: string;
								updated_at: string;
							}) => {
								return { id: item.id, name: item.name };
							},
						);
						setDoctors(formattedDoctorsData);

						// Formatting appointment type data
						const formattedAppointmentTypeData =
							appointmentType.map(
								(item: AppointmentTypesInterface) => {
									const {
										id,
										name,
										visit_type,
										duration,
										locations,
										...rest
									} = item;
									return {
										id: id,
										name: name,
										visit_type: visit_type,
										duration: duration,
										locations: locations,
										email_type: "",
										reminder_type: "",
										start_time: "",
										end_time: "",
										created_by: "",
									};
								},
							);

						setAppointmentTypes(formattedAppointmentTypeData);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (open) {
			const currentTime = dayjs(); // Get the current time
			setValue("appointmentFromTime", currentTime); // Set the current time to the "From" field

			if (appointmentType && appointmentType.duration) {
				setValue(
					"appointmentToTime",
					currentTime.add(appointmentType.duration, "minutes"),
				); // Calculate the "To" time based on duration
			}

			getAppointmentModalData();
			getConfig();
		}
	}, [open]);

	//Displays errors on snackBar
	useEffect(() => {
		if (errors) {
			setSnackBarType("error");
			setSnackBarMessage(capitalizeFirstCharacter(errors.message));
			setOpenSnackBar(true);
		}
	}, [errors]);

	// We need to figure out which id to send in patient_id. If only primary patient is invited we send their id. Otherwise if only secondary patient is invited then we send their id. If both patients are invited we send primary patient id and send is_partner_invited as true
	const findWhichPatientIdToSend = (
		primaryId: number,
		secondaryId: number,
		isPrimaryInvited: boolean,
		isSecondaryInvited: boolean,
	): number => {
		if (isPrimaryInvited === true && isSecondaryInvited === false) {
			return primaryId;
		} else if (isPrimaryInvited === false && isSecondaryInvited === true) {
			return secondaryId;
		} else {
			return primaryId;
		}
	};

	const onSubmit: SubmitHandler<FormInputFields> = async (values) => {
		if (
			values.invitePrimary === false &&
			values.inviteSecondary === false
		) {
			setSnackBarType("error");
			setSnackBarMessage(
				capitalizeFirstCharacter(
					t("Please invite either primary patient or partner"),
				),
			);
			setOpenSnackBar(true);
			return;
		}

		if (values.primaryPatient && values.doctor && values.appointmentType) {
			const appointmentDate = dayjs(values.dateOfAppointment).format(
				"YYYY-MM-DD",
			);

			const appointmentFromTime = dayjs(
				values.appointmentFromTime,
			).format("HH:mm:ss");

			const appointmentToTime = dayjs(values.appointmentToTime).format(
				"HH:mm:ss",
			);

			const appointmentFromInUTC = dayjs(
				`${appointmentDate} ${appointmentFromTime}`,
			)
				.utc()
				.format("YYYY-MM-DD HH:mm:ss");

			const appointmentToInUTC = dayjs(
				`${appointmentDate} ${appointmentToTime}`,
			)
				.utc()
				.format("YYYY-MM-DD HH:mm:ss");

			const payload = {
				appointment_type_id: values.appointmentType.id,
				visit_type: values.visitType,
				...(hideLocation === false && values.clinicLocation
					? {
							location_id: values.clinicLocation.id,
					  }
					: {}),
				patient_id: findWhichPatientIdToSend(
					values.primaryPatient.id,
					values.secondaryPatient ? values.secondaryPatient.id : 0, // If secondaryPatient is null the id will be 0 but that wont be an issue since inviteSecondary will be false (because the checkbox will be invisible and un-clickable)
					values.invitePrimary,
					values.inviteSecondary,
				),
				doctor_id: values.doctor.id,
				from: appointmentFromInUTC,
				to: appointmentToInUTC,
				is_partner_invited:
					values.invitePrimary && values.inviteSecondary
						? true
						: false,
			};

			await createAppointment({
				payload,
				page,
				setErrors,
				setLoading,
			}).then((response: ResponseType) => {
				if (response.success) {
					setSnackBarType("success");
					setSnackBarMessage(
						capitalizeFirstCharacter(response.message),
					);
					setOpenSnackBar(true);
					if (refreshData) {
						refreshData();
					}
					onClose();
					setInitialValues();
					return;
				}
			});
		}
	};

	const renderVisitTypeOptions = (
		type: "virtual" | "onsite" | "both",
	): Array<{ label: string; value: string }> => {
		if (type === "virtual") {
			return [{ label: "Virtual", value: "virtual" }];
		} else if (type === "onsite") {
			return [{ label: "On-Site", value: "onsite" }];
		} else {
			return [
				{ label: t("Virtual"), value: "virtual" },
				{ label: t("On-Site"), value: "onsite" },
			];
		}
	};
	const customPtBRLocaleText = {
		okButtonLabel: t("Ok"),
		cancelButtonLabel: t("Cancel"),
	};
	const renderClinicLocationsDropDown = () => {
		if (hideLocation === false) {
			if (
				appointmentType !== null &&
				appointmentType.locations !== null &&
				visitType === "onsite"
			) {
				return (
					<Grid item xs={12}>
						<Controller
							control={control}
							name="clinicLocation"
							rules={{
								validate: {
									required: (value) => {
										if (!value)
											return t(
												"Please Select A Clinic Location",
											);
									},
								},
							}}
							render={({
								field: { onChange, value, ...rest },
							}) => (
								<Autocomplete
									{...rest}
									id="combo-box-demo"
									options={
										appointmentType.locations || [] // Giving empty array to get rid of typescript error. Locations will always be available here because of the conditional rendering
									}
									getOptionLabel={(option: any) =>
										option.name
									}
									value={value}
									onChange={(event, item) => {
										onChange(item);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Clinic Locations"
											error={
												Object.keys(formErrors).find(
													(item: string) =>
														item ===
														"clinicLocation",
												)
													? true
													: false
											}
											helperText={
												formErrors.clinicLocation
													? formErrors.clinicLocation
															.message
													: ""
											}
										/>
									)}
									renderOption={(props, option) => (
										<li {...props}>
											<Box
												className="flex gap-15"
												padding={1}
											>
												<Avatar
													sx={{
														width: 36,

														height: 36,

														fontSize:
															theme.typography
																.body2.fontSize,
													}}
												>
													{getNameInitials(
														option.name,
													)}
												</Avatar>

												<Typography
													className="fontWeight-medium"
													mb="3px"
													variant="subtitle1"
													sx={{
														color: theme.palette
															.primary.main,
													}}
												>
													{option.name}
												</Typography>
											</Box>
										</li>
									)}
								/>
							)}
						/>
					</Grid>
				);
			}
		}
		return <></>;
	};

	const renderHelperTextOnAppointmentType = () => {
		if (formErrors.appointmentType) {
			return (
				<FormHelperText>
					{formErrors.appointmentType.message || ""}
				</FormHelperText>
			);
		} else if (appointmentType === null) {
			return (
				<FormHelperText
					sx={{
						color: `${theme.palette.white.main} !important`,
					}}
				>
					&nbsp; &nbsp;
				</FormHelperText>
			);
		}
	};

	const renderHelperTextOnVisitType = () => {
		if (appointmentType === null) {
			return (
				<FormHelperText disabled>
					{t("Please select appointment type first")}
				</FormHelperText>
			);
		}

		if (formErrors.visitType) {
			return (
				<FormHelperText>
					{formErrors.visitType.message || ""}
				</FormHelperText>
			);
		}
	};

	const renderInviteSecondary = () => {
		if (patientRelationshipStatus === "single") {
			return <></>;
		}
		if (primaryPatient && secondaryPatient) {
			return (
				<Controller
					name="inviteSecondary"
					control={control}
					render={({ field: { value, ...rest } }) => (
						<FormControlLabel
							{...rest}
							sx={{
								m: 0,
								mb: 3,
							}}
							value={value}
							control={
								<Checkbox
									sx={{
										p: 0,
										m: 0,
										mr: 2,
									}}
									checked={value}
								/>
							}
							label={
								<Box className="dtbl">
									<Box
										className="dtd vMiddle"
										sx={{
											width: 36,
											p: "0 !important",
										}}
									>
										<Avatar
											sx={{
												width: 36,
												height: 36,
												fontSize:
													theme.typography.body2
														.fontSize,
												fontWeight: 400,
												backgroundColor:
													theme.palette.secondary
														.main,
											}}
										>
											{getNameInitials(
												`${secondaryPatient.first_name} ${secondaryPatient.last_name}`,
											)}
										</Avatar>
									</Box>

									<Box className="dtd vMiddle">
										<Typography
											className="fontWeight-medium"
											mb="3px"
											variant="body2"
										>
											{`${secondaryPatient.first_name} ${secondaryPatient.last_name}`}{" "}
											<Typography
												variant="subtitle1"
												color="black.main"
												component="span"
												sx={{
													opacity: 0.5,
												}}
											>
												({t("Partner")})
											</Typography>
										</Typography>
										<Tooltip title={secondaryPatient.email}>
											<Typography
												variant="subtitle1"
												color="black.main"
												noWrap
												sx={{
													opacity: 0.5,
												}}
											>
												{secondaryPatient.email}
											</Typography>
										</Tooltip>
									</Box>
								</Box>
							}
						/>
					)}
				/>
			);
		} else {
			return (
				<Controller
					name="inviteSecondary"
					control={control}
					render={({ field: { value, ...rest } }) => (
						<FormControlLabel
							{...rest}
							sx={{
								m: 0,
								mb: 3,
							}}
							value={value}
							control={
								<Checkbox
									sx={{
										p: 0,
										m: 0,
										mr: 2,
									}}
									checked={value}
								/>
							}
							label={t("Partner")}
						/>
					)}
				/>
			);
		}
	};

	const { configValue } = useGlobalConfig(["date-format"], "value");

	return (
		<>
			{/* Snackbar for showing errors and success messages */}
			<SnackBar
				open={openSnackBar}
				onClose={onSnackBarClose}
				message={snackBarMessage}
				type={snackBarType}
			/>
			<Dialog
				onClose={
					loading
						? () => {}
						: () => {
								onClose();
								setInitialValues();
						  }
				}
				aria-labelledby="customized-dialog-title"
				open={open}
				sx={{
					p: 5,
					"& .MuiDialog-paper": {
						maxWidth: "700px",
						width: 1,
					},
				}}
			>
				<CustomLoader open={loading} />
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent dividers>
						<Box className="flex justify-content-between" mb={4}>
							<Typography variant="h6">
								{t("New appointment")}
							</Typography>
							<IconButton
								autoFocus
								onClick={
									loading
										? () => {}
										: () => {
												onClose();
												setInitialValues();
										  }
								}
								sx={{
									"&:hover svg path,&:focus svg path": {
										fill: `${alpha(
											theme.palette.primary.main,
											0.8,
										)} !important`,
									},
								}}
							>
								<Icon icon="close" />
							</IconButton>
						</Box>
						<Grid
							container
							className="align-item-start"
							spacing={2}
							mb={2}
						>
							<Grid item xs={6}>
								{primaryPatientId ? (
									primaryPatient ? (
										<Controller
											name="invitePrimary"
											control={control}
											render={({
												field: { value, ...rest },
											}) => (
												<FormControlLabel
													{...rest}
													value={value}
													disabled={
														secondaryPatient ===
														null
													}
													sx={{
														m: 0,
														mb: 3,
													}}
													control={
														<Checkbox
															sx={{
																p: 0,
																m: 0,
																mr: 2,
															}}
															checked={value}
														/>
													}
													label={
														<Box className="dtbl">
															<Box
																className="dtd vMiddle"
																sx={{
																	width: 36,
																	p: "0 !important",
																}}
															>
																<Avatar
																	sx={{
																		width: 36,
																		height: 36,
																		fontSize:
																			theme
																				.typography
																				.body2
																				.fontSize,
																		fontWeight: 400,
																	}}
																>
																	{getNameInitials(
																		`${primaryPatient.first_name} ${primaryPatient.last_name}`,
																	)}
																</Avatar>
															</Box>

															<Box className="dtd vMiddle">
																<Typography
																	className="fontWeight-medium"
																	mb="3px"
																	variant="body2"
																>
																	{`${primaryPatient.first_name} ${primaryPatient.last_name}`}{" "}
																	<Typography
																		variant="subtitle1"
																		color="black.main"
																		component="span"
																		noWrap
																		sx={{
																			opacity: 0.5,
																		}}
																	>
																		(
																		{t(
																			"Primary",
																		)}
																		)
																	</Typography>
																</Typography>
																<Tooltip
																	title={
																		primaryPatient.email
																	}
																>
																	<Typography
																		variant="subtitle1"
																		color="black.main"
																		noWrap
																		sx={{
																			opacity: 0.5,
																		}}
																	>
																		{
																			primaryPatient.email
																		}
																	</Typography>
																</Tooltip>
															</Box>
														</Box>
													}
												/>
											)}
										/>
									) : (
										<></>
									)
								) : (
									<Controller
										control={control}
										name="primaryPatient"
										rules={{
											validate: {
												required: (value) => {
													if (!value)
														return "Please Select A Patient";
												},
											},
										}}
										render={({
											field: { onChange, value, ...rest },
										}) => (
											<Autocomplete
												id="combo-box-demo"
												options={patients}
												getOptionLabel={(item) =>
													item.first_name
														? `${item.first_name} ${item.last_name}`
														: ""
												}
												value={value}
												onChange={(event, item) => {
													onChange(item);
												}}
												{...rest}
												renderInput={(params) => (
													<TextField
														{...params}
														label={t(
															"Invite lead/patient",
														)}
														error={
															Object.keys(
																formErrors,
															).find(
																(
																	item: string,
																) =>
																	item ===
																	"patient",
															)
																? true
																: false
														}
														helperText={
															formErrors.primaryPatient
																? formErrors
																		.primaryPatient
																		.message
																: ""
														}
													/>
												)}
												renderOption={(
													props,
													option,
												) => (
													<li {...props}>
														<Box
															className="flex gap-15"
															padding={1}
														>
															<Avatar
																sx={{
																	width: 36,
																	height: 36,
																	fontSize:
																		theme
																			.typography
																			.body2
																			.fontSize,
																	fontWeight: 400,
																}}
															>
																{getNameInitials(
																	`${option.first_name} ${option.last_name}`,
																)}
															</Avatar>

															<Typography
																className="fontWeight-medium"
																mb="3px"
																variant="subtitle1"
																sx={{
																	color: theme
																		.palette
																		.primary
																		.main,
																	wordBreak:
																		"break-word",
																}}
															>
																{`${option.first_name} ${option.last_name}`}
															</Typography>
														</Box>
													</li>
												)}
											/>
										)}
									/>
								)}
							</Grid>
							<Grid item xs={6}>
								{renderInviteSecondary()}
							</Grid>
							<Grid item xs={6}>
								<FormControl
									error={
										Object.keys(formErrors).find(
											(item: string) =>
												item === "appointmentType",
										)
											? true
											: false
									}
									fullWidth
								>
									<InputLabel id="demo-simple-select-label">
										{t("Appointment type")}
									</InputLabel>
									<Controller
										name="appointmentType"
										control={control}
										rules={{
											validate: {
												required: (value) => {
													if (value === null) {
														return t(
															"Appointment type is required",
														);
													}
												},
											},
										}}
										render={({
											field: { value, onChange, ...rest },
										}) => (
											<Select
												{...rest}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label={t("Appointment type")}
												renderValue={(value) =>
													value.name
												}
												value={value ? value : ""}
												onChange={(e) => {
													onChange(e);
													const value = e.target
														.value as AppointmentType;
													const startTime = getValues(
														"appointmentFromTime",
													);
													if (
														startTime &&
														value.duration
													) {
														setValue(
															"appointmentToTime",
															dayjs(
																startTime,
															).add(
																value.duration,
																"minutes",
															),
														);
													}
												}}
											>
												{AppointmentTypes.map(
													(
														item: any, // Giving this any because mui doesn't allow menu item to have an object as value. Everything should work fine tho.
													) => (
														<MenuItem
															key={item.id}
															value={item}
														>
															{item.name}
														</MenuItem>
													),
												)}
											</Select>
										)}
									/>
									{renderHelperTextOnAppointmentType()}
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl
									disabled={appointmentType === null}
									error={
										Object.keys(formErrors).find(
											(item: string) =>
												item === "visitType",
										)
											? true
											: false
									}
									fullWidth
								>
									<InputLabel id="demo-simple-select-label">
										{t("Visit type")}
									</InputLabel>
									<Controller
										name="visitType"
										control={control}
										rules={{
											validate: {
												required: (value) => {
													if (!value)
														return t(
															"Visit type is required",
														);
												},
											},
										}}
										render={({
											field: { value, onChange, ...rest },
										}) => (
											<Select
												{...rest}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label={t("Visit type")}
												disabled={
													appointmentType === null
												}
												value={value ? value : ""}
												onChange={(e) => {
													setValue(
														"clinicLocation",
														null,
													);
													onChange(e);
												}}
											>
												{appointmentType ? (
													renderVisitTypeOptions(
														appointmentType.visit_type,
													).map((item) => (
														<MenuItem
															key={item.value}
															value={item.value}
														>
															{item.label}
														</MenuItem>
													))
												) : (
													<></>
												)}
											</Select>
										)}
									/>
									{renderHelperTextOnVisitType()}
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<Controller
									control={control}
									name="doctor"
									rules={{
										validate: {
											required: (value) => {
												if (!value)
													return t(
														"Please Select A Doctor",
													);
											},
										},
									}}
									render={({
										field: { onChange, value, ...rest },
									}) => (
										<Autocomplete
											id="combo-box-demo"
											options={doctors}
											getOptionLabel={(item) =>
												item.name ? item.name : ""
											}
											value={value}
											onChange={(event, item) => {
												onChange(item);
											}}
											{...rest}
											renderInput={(params) => (
												<TextField
													{...params}
													label={t("Physician")}
													error={
														Object.keys(
															formErrors,
														).find(
															(item: string) =>
																item ===
																"doctor",
														)
															? true
															: false
													}
													helperText={
														formErrors.doctor
															? formErrors.doctor
																	.message
															: ""
													}
												/>
											)}
											renderOption={(props, option) => (
												<li {...props}>
													<Box
														className="flex gap-15"
														padding={1}
													>
														<Avatar
															sx={{
																width: 36,

																height: 36,

																fontSize:
																	theme
																		.typography
																		.body2
																		.fontSize,
															}}
														>
															{getNameInitials(
																option.name,
															)}
														</Avatar>

														<Typography
															className="fontWeight-medium"
															mb="3px"
															variant="subtitle1"
															sx={{
																color: theme
																	.palette
																	.primary
																	.main,
															}}
														>
															{option.name}
														</Typography>
													</Box>
												</li>
											)}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									localeText={customPtBRLocaleText}
									adapterLocale={i18next.resolvedLanguage}
								>
									<Controller
										control={control}
										name="dateOfAppointment"
										rules={{
											required: t(
												"Date of Appointment is required",
											),
											validate: {
												isValid: (value) => {
													if (
														value !== null &&
														isDateValid(
															dayjs(value),
														) === false
													) {
														return t(
															"Date of Appointment is invalid",
														);
													}
												},
												greaterThanTodaysDate: (
													value,
												) => {
													const currentDate = dayjs();
													if (
														dayjs(value).isBefore(
															currentDate,
															"day",
														)
													)
														return `${t(
															"Appointment date must be greater than or equal to",
														)} ${currentDate.format(
															"DD-MM-YYYY",
														)}`;
												},
											},
										}}
										render={({
											field: { ref, ...rest },
										}) => (
											<DatePicker
												label={t("Date of Appointment")}
												openTo="year"
												views={["year", "month", "day"]}
												inputFormat={
													configValue &&
													configValue.dateFormat
														? configValue.dateFormat
														: "DD-MM-YYYY"
												}
												maxDate={dayjs("31-dec-2029")}
												minDate={dayjs()}
												PopperProps={{
													placement: "bottom-start",
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														error={
															Object.keys(
																formErrors,
															).find(
																(
																	item: string,
																) =>
																	item ===
																	"dateOfAppointment",
															)
																? true
																: false
														}
														helperText={
															(formErrors.dateOfAppointment
																? formErrors
																		.dateOfAppointment
																		.message
																: "") ||
															(configValue &&
															configValue.dateFormat ===
																"MM-DD-YYYY"
																? t(
																		"Month, Day, Year",
																  )
																: t(
																		"Day, Month, Year",
																  ))
														}
													/>
												)}
												{...rest}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={3}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									localeText={customPtBRLocaleText}
									adapterLocale={i18next.resolvedLanguage}
								>
									<Controller
										control={control}
										name="appointmentFromTime"
										rules={{
											required: t(
												"From Time is required",
											),
											validate: {
												greaterThanCurrentTime: (
													value,
												) => {
													if (
														isSelectedDateTimeBeforeCurrentDateTime(
															getValues(
																"dateOfAppointment",
															),
															value,
														)
													) {
														return `${t(
															"From time must be greater than",
														)} ${dayjs().format(
															"HH:mm",
														)}`;
													}
												},
											},
										}}
										render={({
											field: { ref, onChange, ...rest },
										}) => (
											<MobileTimePicker
												{...rest}
												label={t("From")}
												inputFormat="hh:mm A"
												onChange={(value) => {
													onChange(value);
													if (
														appointmentType &&
														appointmentType.duration
													) {
														setValue(
															"appointmentToTime",
															dayjs(value).add(
																appointmentType.duration,
																"minutes",
															),
														);
													}
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														error={
															Object.keys(
																formErrors,
															).find(
																(
																	item: string,
																) =>
																	item ===
																	"appointmentFromTime",
															)
																? true
																: false
														}
														helperText={
															formErrors.appointmentFromTime
																? formErrors
																		.appointmentFromTime
																		.message
																: ""
														}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		sx={{
																			p: 0,
																		}}
																	>
																		<Icon icon="time" />
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												)}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={3}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									localeText={customPtBRLocaleText}
									adapterLocale={i18next.resolvedLanguage}
								>
									<Controller
										control={control}
										name="appointmentToTime"
										rules={{
											required: t("To Time is required"),
											validate: {
												greaterThanFromTime: (
													value,
												) => {
													if (
														isToDateTimeBeforeOrSameAsFromDateTime(
															getValues(
																"dateOfAppointment",
															),
															getValues(
																"appointmentFromTime",
															),
															value,
														)
													) {
														return `${t(
															"To time must be greater than from time",
														)}`;
													}
												},
											},
										}}
										render={({
											field: { ref, ...rest },
										}) => (
											<MobileTimePicker
												label={t("To")}
												inputFormat="hh:mm A"
												renderInput={(params) => (
													<TextField
														{...params}
														error={
															Object.keys(
																formErrors,
															).find(
																(
																	item: string,
																) =>
																	item ===
																	"appointmentToTime",
															)
																? true
																: false
														}
														helperText={
															formErrors.appointmentToTime
																? formErrors
																		.appointmentToTime
																		.message
																: ""
														}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		sx={{
																			p: 0,
																		}}
																	>
																		<Icon icon="time" />
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												)}
												{...rest}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							{renderClinicLocationsDropDown()}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Stack direction="row" alignItems="center" spacing={3}>
							<Button
								autoFocus
								disabled={loading}
								onClick={() => {
									onClose();
									setInitialValues();
								}}
								variant="text"
								aria-label="Cancel"
								sx={{
									"&:hover,&:focus": {
										color: `${alpha(
											theme.palette.link.main,
											0.8,
										)} !important`,
									},
								}}
							>
								{t("Cancel")}
							</Button>
							<Button
								variant="secondary"
								sx={{ padding: "13px 30px" }}
								disabled={loading}
								type="submit"
								aria-label="Save &amp; send email"
							>
								{t("Save & send email")}
							</Button>
						</Stack>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}
