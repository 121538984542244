import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface SnackBarProps {
	open: boolean;
	onClose: () => void;
	message: string;
	type: "success" | "error" | "warning" | "info";
	hideAfter?: number;
	verticalAnchor?: "top" | "bottom";
	horizontalAnchor?: "left" | "center" | "right";
	width?: string | number;
}

export default function SnackBar({
	open,
	onClose,
	message,
	type,
	hideAfter = 3000,
	verticalAnchor = "top",
	horizontalAnchor = "center",
	width = "auto",
}: SnackBarProps) {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: verticalAnchor,
				horizontal: horizontalAnchor,
			}}
			open={open}
			autoHideDuration={hideAfter}
			onClose={onClose}
			sx={{
				width: width,
				zIndex: 9999999,
			}}
		>
			<Alert variant="filled" onClose={onClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	);
}
