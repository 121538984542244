import Script from "next/script";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface ZendeskWidgetProps {
	zendeskKey: string;
}

interface ZendeskWidgetHandles {
	logout: () => void;
}

// Declare the global Zendesk function
declare var zE: (
	command: string,
	action?: string,
	fn?: (callback: (jwt: any) => void) => void,
) => void;

const ZendeskWidget = forwardRef<ZendeskWidgetHandles, ZendeskWidgetProps>(
	(props, ref) => {
		// State to track if the Zendesk script has loaded
		const [scriptLoaded, setScriptLoaded] = useState(false);
		// State to store the token
		const [token, setToken] = useState("");

		// Function to log out the user from Zendesk
		const logout = () => {
			zE("messenger", "logoutUser");
			zE("messenger", "hide");
		};

		// Use Imperative Handle to expose the logout method to parent components
		useImperativeHandle(ref, () => ({
			logout,
		}));

		// Get the token from the Redux store
		const reduxToken = useSelector(
			(state: RootState) => state.zendeskAuth.token,
		);

		// Effect to handle token and script loading changes
		useEffect(() => {
			// If token is not in Redux, fetch from local storage
			if (!reduxToken) {
				const localStorageToken = localStorage.getItem("zendeskToken");
				if (localStorageToken) {
					setToken(localStorageToken);
				} else {
					console.error(
						"Token is not available in Redux store or local storage",
					);
				}
			} else {
				setToken(reduxToken);
			}
		}, [reduxToken]);

		// Effect to login user when both token and script are ready
		useEffect(() => {
			if (token && scriptLoaded) {
				zE("messenger", "loginUser", function (callback: any) {
					callback(token);
					zE("messenger", "show");
				});
			}
		}, [token, scriptLoaded]);

		return (
			<>
				{/* Load the Zendesk script */}
				<Script
					src={`https://static.zdassets.com/ekr/snippet.js?key=${props.zendeskKey}`}
					id="ze-snippet"
					strategy="afterInteractive"
					onLoad={() => {
						// Set scriptLoaded to true when the script loads
						setScriptLoaded(true);
					}}
				/>
			</>
		);
	},
);

// Set the display name for the component
ZendeskWidget.displayName = "ZendeskWidget";
export default ZendeskWidget;
export type { ZendeskWidgetHandles };
