import { API } from "aws-amplify";
import { ResponseType } from "../helpers/types";
import { apiName } from "../helpers/utils";
import { mainDb } from "../helpers/db.model";

interface GetPatientsProps {
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface GetDoctorsProps {
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface GetAppointmentTypesProps {
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface GetDoctorsByIdProps {
	id: number;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface GetAppointmentModalDataProps {
	id?: number;
	isPatientInLeads?: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface CreateAppointmentProps {
	payload: {
		appointment_type_id: number;
		visit_type: string;
		location_id?: number;
		patient_id: number; // Will send id of whichever patient is invited. if both are invited, we send primary patient id and is_partner_invited as true
		doctor_id: number;
		from: string;
		to: string;
		is_partner_invited: boolean; // If both patient and partner are invited this will be true
	};
	page: "lead" | "patient";
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface UpdateAppointmentProps {
	payload: {
		id: number; // Id of appointment
		appointment_type_id: number;
		visit_type: string;
		location_id?: number;
		patient_id: number; // Will send id of whichever patient is invited. if both are invited, we send primary patient id and is_partner_invited as true
		doctor_id: number;
		from: string;
		to: string;
		is_partner_invited: boolean; // If both patient and partner are invited this will be true
	};
	page: "lead" | "patient";
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface CancelAppointmentProps {
	payload: {
		id: number; // Id of appointment
		patient_id: number;
		appointment_cancelled_reason_id: number;
		other_text?: string;
	};
	page: "lead" | "patient";
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

interface CancelAppointmentReasonsProps {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			data: Array<any>;
			message: string;
			success: boolean;
		} | null>
	>;
}

export const useNewAppointmentApi = () => {
	const getPatients = async ({ setErrors }: GetPatientsProps) => {
		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.get(apiName, `api/leads-form-submitted/list/drop-down`, {})
			.then((response) => {
				responseData = response;
			})
			.catch((error) => {
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	const createAppointment = async ({
		payload,
		page,
		setLoading,
		setErrors,
	}: CreateAppointmentProps) => {
		setLoading(true);

		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.post(
			apiName,
			page === "patient"
				? `api/patient/create-appointment`
				: `api/leads-appointments/create`,
			{ body: payload },
		)
			.then((response) => {
				setLoading(false);
				responseData = response;
			})
			.catch((error) => {
				setLoading(false);
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	const updateAppointment = async ({
		payload,
		page,
		setLoading,
		setErrors,
	}: UpdateAppointmentProps) => {
		setLoading(true);

		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.put(
			apiName,
			page === "patient"
				? `api/patient/update-appointment`
				: `api/leads-appointments/update`,
			{ body: payload },
		)
			.then((response) => {
				responseData = response;
			})
			.catch((error) => {
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	const cancelAppointment = async ({
		payload,
		page,
		setLoading,
		setErrors,
	}: CancelAppointmentProps) => {
		setLoading(true);

		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.post(
			apiName,
			page === "patient"
				? `api/patient/cancel-appointment`
				: `api/leads-appointments/cancel`,
			{ body: payload },
		)
			.then((response) => {
				setLoading(false);
				responseData = response;
			})
			.catch((error) => {
				setLoading(false);
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	const getCancelAppointmentReasons = async ({
		setLoading,
		setErrors,
	}: CancelAppointmentReasonsProps) => {
		setLoading(true);

		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.get(apiName, "api/conf/appointment-cancelled-reasons", {})
			.then((response) => {
				setLoading(false);
				responseData = response;
			})
			.catch((error) => {
				setLoading(false);
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	const getAppointmentModalFieldsData = async ({
		id, // Will always be primary patient id
		isPatientInLeads = true, // Only for edit modal
		setErrors,
		setLoading,
	}: GetAppointmentModalDataProps) => {
		setLoading(true);

		setErrors(null);

		let responseData: ResponseType = {
			data: null,
			message: "Something went wrong",
			success: false,
		};

		await API.get(
			apiName,
			isPatientInLeads
				? `api/aggregate/lead-doctor-appointment-type${
						id ? `?lead_id=${id}` : ""
				  }`
				: `api/aggregate/patient-doctor-appointment-type${
						id ? `?patient_id=${id}` : ""
				  }`,
			{},
		)
			.then((response) => {
				responseData = response;
			})
			.catch((error) => {
				if (error.response.status !== 422)
					setErrors(error.response.data);
			});

		return responseData;
	};

	return {
		getPatients,
		createAppointment,
		updateAppointment,
		cancelAppointment,
		getCancelAppointmentReasons,
		getAppointmentModalFieldsData,
	};
};
